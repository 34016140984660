import React from 'react';
import {hydrate, render} from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'assets/style/Global.style';
import AuthProvider from 'context/AuthProvider';
import Routes from './router';
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { liveSiteUrl } from "./util/serverUtil";

// if(liveSiteUrl === 'https://patients.edentalportal.com/'){
//     //Google Analytics
//     ReactGA.initialize('UA-146514891-1');
//     //Facebook Pixel
//     const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
//     const options = {
//         autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//         debug: false, // enable logs
//     };
//     ReactPixel.init('UA-146514891-1', advancedMatching, options);
//     //End Facebook Pixel
// }else {
//     console.log("dev portal");
// }


const App = () => (
  <Provider store={configureStore()}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// ReactDOM.render(<App />, document.getElementById('root'));
