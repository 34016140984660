import { Service } from './serverUtil';

export function Get(url) {
    return Service({
        method: 'GET',
        url,
    })
}

export function Post(url, data) {
    return Service({
        method: 'POST',
        url,
        data
    })
}

export function Delete(url) {
    return Service({
        method: 'DELETE',
        url
    })
}
export function Patch(url, data) {
    return Service({
        method: 'PATCH',
        url,
        data
    })
}