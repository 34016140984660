import React, { useState, useContext } from 'react';
import Sticky from 'react-stickynode';
import { withRouter } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Text from 'components/UI/Text/Text';
import TextLink from 'components/UI/TextLink/TextLink';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { DOCTOR_PREVIEW_PAGE, DOCTOR_DASHBOARD_PAGE } from 'settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import Container from 'components/UI/Container/Container';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

import edentalDoctor from 'assets/images/edental_logo.svg';
//const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;

export default withRouter(function Header({ location }) {
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn, user } = useContext(AuthContext);

  const { width } = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'default';
    let userData = localStorage.getItem('userData');
    const userD = JSON.parse(userData);
    const avatarImg = (userD && userD.avatar !== 'undefined' && userD.avatar !== null && userD.avatar.length > 0)?userD.avatar:'http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png';

  return (
    <HeaderWrapper>

      <Sticky top={headerType === 'transparent' ? -1 : 0} innerZ={10001} activeClass="isHeaderSticky">
        {width > 991 ? (

              <Navbar
                  logo={<><Logo withLink linkTo={loggedIn? DOCTOR_DASHBOARD_PAGE : "/"} src={edentalDoctor}/></>}

                  navMenu={<MainMenu />}
                  authMenu={<AuthMenu isLogin={loggedIn} />}

                  isLogin={loggedIn} avatar={<Logo src={avatarImg} />}
                  profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} userData={userD} />}
                  headerType={headerType}
                  searchComponent={<NavbarSearch />}
                  location={location}
                  searchVisibility={searchVisibility}
              />

        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
                <Logo withLink linkTo={loggedIn? DOCTOR_DASHBOARD_PAGE : "/"} src={edentalDoctor}/>
            </LogoArea>
            <Button className={`hamburg-btn ${state ? 'active' : ''}`} onClick={sidebarHandler}>
              <span />
              <span />
              <span />
            </Button>

            <Drawer placement="right" closable={false} onClose={sidebarHandler} width="285px" className="mobile-header" visible={state} >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content={userD?.first_name + " " + userD?.last_name} />
                    <TextLink
                      link={DOCTOR_PREVIEW_PAGE + "/" + userD.site_url }
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
