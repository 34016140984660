import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
    HOME_PAGE,
    HOME_PAGE_STUDENTS,
    HOME_PAGE_PATIENTS
} from 'settings/constant';

const MainMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="1">
        {/*<NavLink to={`${HOME_PAGE_PATIENTS}`}> For Patients</NavLink>
        <a href="https://patients.edentalportal.com/">For Patients</a>*/}
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
