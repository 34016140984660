import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const FeedbackContain = styled.div`
        .feedback-btn {
            position: fixed;
            z-index: 9999;
            right: -85px;
            top: 260px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-weight: 500;
            background: #324755 !important;
            border-width: 1px 0 1px 1px;
            border-color: #fff !important;
            border-style: solid;
            padding: 0 15px 0 0;
            height: 46px;
            border-radius: 32px 0 0 32px;
            width: 100%;
            max-width: 140px;
            &:hover {
                right: -5px;
            }
            img {
                width: 57px;
            }
            span {
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 1px;
            }
        }
    }
`;

export default FeedbackContain;
