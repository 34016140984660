import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Popover, Badge, Button } from 'antd';
import {
  BellOutlined
} from "@ant-design/icons";
import { HOME_PAGE, VIEW_ALL_QUESTIONS_PAGE, NOTIFICATION } from 'settings/constant';
import { MenuWrapper } from 'components/Navbar/Navbar.style';

const AuthMenu = ({ className, isLogin = false }) => {
  // console.log("isLogin +++ ", isLogin)
  return (
    <MenuWrapper className="main_menu mobile-out">
      <Menu className={className}>
        <Menu.Item key="1" className="head-right head-qs">
          <NavLink to={VIEW_ALL_QUESTIONS_PAGE}> Questions & Answers</NavLink>
        </Menu.Item>
        {isLogin &&
          <Menu.Item key="1" className="head-right head-qs pr-30">
            <Popover
              placement="bottom"
              title={"Notifications"}
              content={"No Notification"}
              trigger="click"
              content={
                <div>
                  <NavLink to={NOTIFICATION}>All notification </NavLink>
                </div>
              }
            >
              <Badge count={0}>
                <BellOutlined />
              </Badge>
            </Popover>
          </Menu.Item>
        }
        {
          !isLogin &&
          <>
            <Menu.Item key="0" >
              <NavLink to={HOME_PAGE}> Login / Signup </NavLink>
            </Menu.Item>
          </>
        }
      </Menu>
    </MenuWrapper>
  );
};

export default AuthMenu;
