// General Page Section
export const HOME_PAGE = '/';

// Verification Process Pages
export const SUCCESS_REGISTER = "/success-registered";
export const VERIFY_PAGE = '/verify';
export const EMAIL_VERIFY_PAGE = '/email-verify';
export const VERIFIED_PAGE = '/verified';
export const UPLOAD_PAGE = '/upload';
export const VERIFICATION_PAGE = '/verification';
export const APPROVE_PAGE = '/approved';

// Profile Page Section
export const DOCTOR_PROFILE_PAGE = '/profile';
export const DOCTOR_EDIT_PROFILE_PAGE = '/edit-profile';
export const DOCTOR_DASHBOARD_PAGE = '/dashboard';
export const DOCTOR_PREVIEW_PAGE = '/dentist';
export const DOCTOR_PREVIEW = '/dentist/';


export const DOCTOR_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';

export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const CHANGE_USER_PASSWORD_PAGE = '/update-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

export const HOME_PAGE_STUDENTS = '/students';
export const HOME_PAGE_PATIENTS = '/patients';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/post';

// Articles Page
export const ARTICLES_PAGE = '/articles';
export const ADD_ARTICLES_PAGE = '/add-articles';
export const EDIT_ARTICLES_PAGE = '/edit-articles';
export const VIEW_ARTICLES_PAGE = '/view-articles';

//Profile Questions
export const PROFILE_QUESTIONS_PAGE = '/view-questions';
export const PROFILE_QUESTIONS_DETAILS = '/dental-problems/';
export const ANSWERED_QUESTIONS_PAGE = '/answered-questions';
export const ALL_QUESTION_PAGE = '/QuestReplies';

// View All Questions
export const VIEW_ALL_QUESTIONS_PAGE = '/dental-problem';
export const QUESTIONS_DETAILS = '/dental-problem/';
export const DOCTOR_REVIEW_PAGE = '/doctor-reviews/';
export const DOCTOR_REVIEW_PAGE_NOFORWARD = '/doctor-reviews';
export const MY_APPOINTMENT_PAGE = '/appointments';

export const SUCCESSFUL_WORK_PAGE = '/successful-work';
export const MY_BROADCAST_PAGE = '/my-broadcast';
export const SET_PASSWORD_PAGE = '/set-password';
export const APPOINTMENT_REQUEST_PAGE = '/appointment-requests';
export const MY_SLOTS_PAGE = '/my-slots';
export const MY_PATIENTS_PAGE = '/patients';

export const PROCEDURE_DETAIL_PAGE_FORWARDSLASH = '/procedure-details/';
export const PROCEDURE_LIST_PAGE = '/procedure-list';
export const NOTIFICATION = '/notification';

export const TC_PAGE = '/terms-conditions';

