import { Post, Patch, Get, Delete } from "../util/APIClient";

//Login
export function login(body) {
  return Post("Auth/login", body);
}

//Register
export function register(body) {
  return Post("auth/register", body);
}

//Profile Update
export function profileUpdate(body) {
  return Post("Profile/updateDoctorProfile", body);
}

//Update User Tab
export function updateuserTab(body) {
  return Post("admin/users/updateuserTab", body);
}

//get Procedure Group
export function getProcedureGroup(body) {
  return Get("GeneralMethods/getProcedureGroup", body);
}

//get Procedure List
export function getProcedureList(body) {
  return Post("GeneralMethods/getProcedureList", body);
}

//Add Question
export function addQuestion(body) {
  return Post("question/addquestion", body);
}

//Question Detail by id
export function questionDetailById(body) {
  return Get(
    "question/getQuestionDetails?site_url=" +
    body.siteUrl +
    "&like_id_user=" +
    body.like_id_user +
    "&accessible=" +
    body.accessible
  );
}

//Question Like
export function addQuestionLike(body) {
  return Post("question/addQuestionLikes", body);
}

//Question Flag
export function addQuestionFlag(body) {
  return Post("question/addQuestionFlags", body);
}

//Answer Like
export function addAnswerLike(body) {
  return Post("question/addAnswerLikes", body);
}

//View all Question
export function viewAllQuestion(search) {
  // default status need to add...
  const status = search.status ? search.status : "";
  return Get(
    "Question/getallQuestion?currentPage=" +
    search.currentPage +
    "&pageSize=" +
    search.pageSize +
    "&id_procedure=" +
    search.id_procedure +
    "&ans_access=" +
    search.id_answer +
    "&id_user=" +
    search.id_user +
    "&self_access=" +
    search.self_access +
    "&order_by=" +
    search.order_by +
    "&like_id_user=" +
    search.like_id_user +
    "&is_answered=" +
    search.is_answered +
    "&status=" +
    status
  );
}

//Get All Doctor Question Answer
export function getAllDoctorQuestionAnswer(body) {
  const id_user_text = body.id_user ? body.id_user : '';
  const currentPage_text = body.currentPage ? body.currentPage : '';
  const pageSize_text = body.pageSize ? body.pageSize : '';
  const id_procedure_text = body.id_procedure ? body.id_procedure : '';
  const order_by_text = body.order_by ? body.order_by : '';
  const liked_user_text = body.liked_user ? body.liked_user : '';

  return Get("question/getAllDoctorQuestionAnswer?id_user=" + id_user_text +
    '&currentPage=' + currentPage_text +
    '&pageSize=' + pageSize_text +
    '&id_procedure=' + id_procedure_text +
    '&order_by=' + order_by_text +
    '&liked_user=' + liked_user_text
  );
}

//Add feedback
export function addFeeback(body) {
  return Post("home/addtestimonial", body);
}

//View all review
export function viewAllReviews(body) {
  return Get(
    "review/getProcedureReview?id_procedure=" +
    body.id +
    "&order_by=" +
    body.order_by +
    "&currentPage=" +
    body.currentPage +
    "&pageSize=" +
    body.pageSize
  );
}

//Add Procedure Review
export function addProcedureReview(body) {
  return Post("review/addProcedureReview", body);
}

//View all Country
export function viewCountryList() {
  return Get("GeneralMethods/getCountryList");
}

//View all Country
export function viewDoctorList(search) {
  const currentPage = search?.currentPage ? search?.currentPage : 1;
  const pageSize = search?.pageSize ? search?.pageSize : 10;
  const id_procedure_detail = search?.id_procedure_detail ? search?.id_procedure_detail : "";
  const id_country = search?.id_country ? search?.id_country : "";
  const experience = search?.experience ? search.experience : "";
  const rating = search?.rating ? search.rating : "";
  const language = search?.language ? search.language : "";
  const order_by = search?.order_by ? search.order_by : "";
  const verified_dentist = search?.verified_dentist
    ? search.verified_dentist
    : "";
  const id_user = search?.id_user ? search.id_user : "";
  const speciality = search?.speciality ? search.speciality : "";
  const name = search?.name ? search.name : "";
  const id_insurance = search?.id_insurance ? search?.id_insurance : "";
  const state = search?.state ? search?.state : "";

  return Get(
    "doctors/findDoctor?currentPage=" +
    currentPage +
    "&pageSize=" +
    pageSize +
    "&id_procedure_detail=" +
    id_procedure_detail +
    "&id_country=" +
    id_country +
    "&state=" +
    state +
    "&experience=" +
    experience +
    "&rating=" +
    rating +
    "&language=" +
    language +
    "&order_by=" +
    order_by +
    "&verified_dentist=" +
    verified_dentist +
    "&id_user=" +
    id_user +
    "&speciality=" +
    speciality +
    "&name=" +
    name +
    "&id_insurance=" +
    id_insurance +
    "&img_filter=" +
    1
  );
}

// get all country which has doctors
export function getDentistAvailableCountryList() {
  return Get("GeneralMethods/getDentistAvailableCountryList");
}

//Add Doctor review
export function addDoctorReview(body) {
  return Post("home/adddoctorreview", body);
}

//Get all Article
export function getAllArticle(body) {
  return Get(
    "admin/blogs/getallarticles?&id_procedure_detail=" +
    body.procedure_id +
    "&order_by=" +
    body.order_by +
    "&currentPage=" +
    body.currentPage +
    "&pageSize=" +
    body.pageSize
  );
}

//Get all Trending Procedure List
export function getTrendingProcedureList(body) {
  return Get("GeneralMethods/getTrendingProcedureList?limit=" + body.limit);
}

//Get all Trending Doctor/Dentist List
export function getTrendingDoctorList(body) {
  return Get("GeneralMethods/getTrendingDoctorList?limit=" + body.limit);
}

//Get all procedureList
export function getProcedureLists(body) {
  return Post("admin/procedure/procedureList", body);
}

//Get all getDentalProblem
export function getDentalProblem(body) {
  return Get(
    "GeneralMethods/getDentalProblem?procedure_detail_id=" +
    body.procedure_detail_id +
    "&procedure_group_id=" +
    body.procedure_group_id
  );
}

//get all article for doctor profile page
export function getDocotrArticle(body) {
  return Post("admin/users/DoctorArticles", body);
}

//get all questions for doctor profile page
export function getDocotrQuestion(body) {
  return Post("admin/users/DoctorQuestions", body);
}

//get all doctor review for doctor profile page
export function getDocotrReview(body) {
  return Post("admin/users/DoctorReviews", body);
}

//get all admin blogs details
export function getAdminBlogsDetails(body) {
  return Post("admin/Blogs/blogDetails", body);
}

//get all blogs
export function getallBlogs(body) {
  return Get(
    "home/getallBlogs?currentPage=" +
    body.currentPage +
    "&pageSize=" +
    body.pageSize
  );
}

//Get Blog Name List
export function getblognamelist() {
  return Get("GeneralMethods/getblognamelist");
}

//user profile content
export function userprofilecontent() {
  return Get("admin/users/userprofilecontent");
}

//user profile
export function userProfile(body) {
  return Post("admin/users/userprofile", body);
}

//user profile
export function updateVisitorProfile(body) {
  return Post("Profile/updateVisitorProfile", body);
}

//visitorProcedureDetails
export function visitorProcedureDetails(body) {
  return Post("admin/users/visitorProcedureDetails", body);
}

//Doctor Profile Counts
export function postDoctorProfileCounts(body) {
  return Post("profile/postDoctorProfileCounts", body);
}

//Doctor Detail By Location
export function doctorDetailByLocation(body) {
  return Post("admin/users/doctorDetailByLocation", body);
}

//Search Procedure new
export function procedureSearch(body) {
  return Get(
    "admin/procedure/searchProcedurelist?search_text=" + body.search_text
  );
}

//Get All Reviews
export function getAllReviews() {
  return Get("home/getallreviews");
}

//Get Procedure Name List
export function getProcedureNameList() {
  return Get("GeneralMethods/getProcedureNameList");
}

//Get Visitor Question Procedure List
export function getVisitorQuestionProcedureList(body) {
  return Post("GeneralMethods/getVisitorQuestionProcedureList", body);
}

//procedure Average Cost
export function procedureAverageCost(body) {
  return Post("admin/users/procedureAverageCost", body);
}

//remove Doctor Procedure Image
export function removeDoctorProcedureImage(body) {
  return Post("Profile/removeDoctorProcedureImage", body);
}

//get Specilizations
export function getSpecilizations() {
  return Get("GeneralMethods/getSpecilizations");
}

//get All Trending Blogs
export function getallTrendingBlogs() {
  return Get("home/getallTrendingBlogs");
}

//get All Featured Blogs
export function getallFeaturedBlogs() {
  return Get("home/getallFeaturedBlogs");
}

//get Ip Address
export function getIpAddress() {
  return Get("http://ip-api.com/json");
}

//Change Password
export function changePassword(body) {
  return Post("Auth/changepassword", body);
}

// Get Faq for patient module
export function getFaqData(body) {
  return Get("faq/getfaq?id_procedure=" + body.id_procedure);
}

// Get Related Procedure List
export function getRelatedProcedureList(body) {
  return Get(
    "admin/procedure/getRelatedProcedureList?id_procedure=" + body.id_procedure
  );
}

// Get Question Search..
export function questionSearch(body) {
  return Post("Question/searchQuestions", body);
}

// Add Question keyword..
export function addQuestionKeyword(body) {
  return Post("Question/addQuestionKeyword", body);
}

// Question Search  APIS...

// Search Question on keyword
export function getAllQuestionsByKeyword(body) {
  return Get(
    "Question/getAllQuestionsByKeyword?pageSize=" +
    body?.pageSize +
    "&currentPage=" +
    body?.currentPage +
    "&keyword=" +
    body?.keyword +
    "&like_id_user=" +
    body.like_id_user +
    "&id_procedure=" +
    body?.id_procedure
  );
}

// search proviouslyAskedQuestion
export function proviouslyAskedQuestion(body) {
  const id_answered_doctor = body.id_answered_doctor
    ? body.id_answered_doctor
    : "";
  return Get(
    "Question/proviouslyAskedQuestion?pageSize=" +
    body?.pageSize +
    "&currentPage=" +
    body?.currentPage +
    "&keyword=" +
    body?.keyword +
    "&like_id_user=" +
    body.like_id_user +
    "&ans_access=" +
    body.ans_access +
    "&id_procedure=" +
    body?.id_procedure +
    "&id_answered_doctor=" +
    id_answered_doctor
  );
}

// Search mostSearchedQuestion
export function mostSearchedQuestion(body) {
  return Get(
    "Question/mostSearchedQuestion?pageSize=" +
    body?.pageSize +
    "&currentPage=" +
    body?.currentPage +
    "&keyword=" +
    body?.keyword +
    "&like_id_user=" +
    body.like_id_user +
    "&ans_access=" +
    body.ans_access +
    "&id_procedure=" +
    body?.id_procedure
  );
}

// Search recentlyAnsweredQuestion
export function recentlyAnsweredQuestion(body) {
  return Get(
    "Question/recentlyAnsweredQuestion?pageSize=" +
    body?.pageSize +
    "&currentPage=" +
    body?.currentPage +
    "&keyword=" +
    body?.keyword +
    "&like_id_user=" +
    body.like_id_user +
    "&ans_access=" +
    body.ans_access +
    "&id_procedure=" +
    body?.id_procedure
  );
}

// Search recentlyAnsweredQuestion
export function addQuestionView(body) {
  return Post("Question/addQuestionView", body);
}

// Get Language data
export function getDentistLanguageList() {
  return Get("GeneralMethods/getDentistLanguageList");
}

// get getAvailDoctorProcedureList
export function getAvailDoctorProcedureList() {
  return Get("GeneralMethods/getAvailDoctorProcedureList");
}

// Add Common Review
export function addCommonReview(body) {
  return Post("admin/Review/addCommonReview", body);
}

// get State List
export function getStateList() {
  return Get("GeneralMethods/getStateList?country_id=230");
}

// get Location
export function getLocation() {
  return Get(
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
    "25.7780296" +
    "," +
    "73.32446139999999" +
    "&sensor=" +
    true +
    "&key=" +
    "AIzaSyAlRNe5zlR-wepiBv2lmIdrgM33ACjoaes"
  );
}

// get Insurence List
export function getInsurenceList() {
  return Get("GeneralMethods/getInsurenceList");
}

// Get Related Question
export function getRelatedQuestion(body) {
  const question_text = body?.question_text ? body.question_text : "";
  const id_procedure = body?.id_procedure ? body.id_procedure : "";
  return Get(
    "question/relatedQuestion?question_text=" +
    question_text +
    "&id_procedure=" +
    id_procedure
  );
}

// Get Popular Question
export function getPopularQuestion() {
  return Get("question/getPopularQuestion");
}

// Get Procedure Question
export function getProcedureQuestion(body) {
  return Post("question/getProcedureQuestion", body);
}

// Get User Answered Question
export function getUserAnsweredQuestion(body) {
  return Get("question/getUserAnsweredQuestion?id_user=" + body.id_user);
}

// get Latitude, Longitude using location name
export function locationUsingLatitudeLongitude(body) {
  return Get("https://maps.googleapis.com/maps/api/geocode/json?address=" + body.locationName + "&key=AIzaSyCaEdPzmSjvzApWwWdZMHqsHimlCxgyUj8")
}

//Book doctor 
export function bookDoctor(body) {
  return Post("doctors/bookDoctor", body);
}

// Add Doctor Review Comment
export function addDoctorReviewComment(body) {
  return Post("review/addDoctorReviewComment", body);
}

//get My Appointments
export function getMyAppointments(body) {
  const status_text = body?.status ? body.status : "";
  const is_accepted_text = body?.is_accepted ? body.is_accepted : "";
  const from_date_text = body?.from_date ? body.from_date : "";
  const to_date_text = body?.to_date ? body.to_date : "";
  const current_page_text = body?.currentPage ? body.currentPage : "";
  const page_size_text = body?.pageSize ? body.pageSize : "";

  return Get("doctors/getMyAppointmentsDoctor?id_doctor=" + body.id_user +
    '&status=' + status_text +
    '&is_accepted=' + is_accepted_text +
    '&from_date=' + from_date_text +
    '&to_date=' + to_date_text +
    '&currentPage=' + current_page_text +
    '&pageSize=' + page_size_text
  );
}

//get Appointments List
export function getAppointmentList(body) {
  const user_id_text = body?.id_user ? body.id_user : "";
  const current_page_text = body?.currentPage ? body.currentPage : "";
  const page_size_text = body?.pageSize ? body.pageSize : "";

  return Get("doctors/getMyAppointmentsDoctor?id_doctor=" + user_id_text + '&get_doctor_list' +
    '&currentPage=' + current_page_text +
    '&pageSize=' + page_size_text
  );
}

// // get Accepted Appointments 
// export function getAcceptedAppointments(body){
//   return Get("doctors/getMyAppointmentsDoctor?id_doctor=" + body.id_user + '&is_accepted=' + body.is_accepted);
// }

// Approve Appointment
export function approveAppointment(body) {
  return Post("doctors/approveAppointment", body);
}

// Reject Appointment
export function rejectAppointment(body) {
  return Post("doctors/rejectAppointment", body);
}

// Post Answer for Questions 
export function postAnswer(body) {
  return Post("question/addanswer", body);
}

// Edit Answer for Questions 
export function editAnswer(body) {
  return Post("question/editanswer", body);
}

// Doctor profile claim
export function doctorprofileclaim(body) {
  return Post("doctors/doctorprofileclaim", body);
}

// Doctor Detail with email
export function confirmDoctorProfile(body) {
  return Get("doctors/confirmDoctorProfile?email=" + body.email);
}

// Get Patient Review
export function getPatientReview() {
  return Get("doctors/getPatientReview");
}

// get Trending Doctors 
export function getTrendingDoctors(body) {
  const location_value = body?.location ? body?.location : '';
  return Get("doctors/getTrendingDoctors?location=" + location_value);
}

// Get My Collections
export function getMyCollections(body) {
  const id_doctor_text = body?.id_doctor ? body?.id_doctor : '';
  const current_page_text = body?.currentPage ? body?.currentPage : '';
  const page_size_text = body?.pageSize ? body?.pageSize : '';
  return Get("doctors/getMyCollections?id_doctor=" + id_doctor_text + '&currentPage=' + current_page_text + '&pageSize=' + page_size_text);
}

// Add My Collections
export function addMyCollections(body) {
  return Post("doctors/addMyCollections", body);
}

//check Valid Username
export function checkValidUsername(body) {
  return Get("Profile/checkValidUsername?user_name=" + body.Username);
}

//get My Patients
export function getMyPatients(body) {
  const id_doctor_value = body?.id_doctor ? body?.id_doctor : '';
  return Get("doctors/getMyPatients?id_doctor=" + id_doctor_value);
}

//send Invite
export function sendInvite(body) {
  return Post("profile/sendInvite", body);
}

//Get All Slot for Doctor
export function getAllSlotforDoctor(body) {
  const doctor_id = body?.id_doctor ? body?.id_doctor : '';
  const search_date_text = body?.search_date ? body?.search_date : '';

  return Get("doctors/getAllSlotforDoctor?id_doctor=" + doctor_id + '&search_date=' + search_date_text);
}

//Get Indivial Slot for Doctor
export function getIndivialSlotforDoctor(body) {
  return Get("doctors/getIndivialSlotforDoctor?id_doctor=" + body.id_doctor + "&id_slot=" + body.id_slot);
}

//Add My Slot 
export function addMySlot(body) {
  return Post("doctors/addMySlot", body);
}

//Edit My Slot 
export function editMySlot(body) {
  return Post("doctors/editMySlot", body);
}

//Check Valid Date for Slot
export function checkValidDateforSlot(body) {
  return Get("doctors/checkValidDateforSlot?id_doctor=" + body.id_doctor + '&slot_date=' + body.slot_date);
}

//Default Slot for Doctor
export function defaultSlotforDoctor(body) {
  return Post("doctors/defaultSlotforDoctor", body);
}

//Doctor Account Settings
export function doctorAccountSettings(body) {
  return Post("doctors/doctorAccountSettings", body);
}

//Get Doctor Account Settings
export function getDoctorAccountSettings(body) {
  return Get("doctors/getDoctorAccountSettings?id_doctor=" + body.id_doctor);
}

//Feedback & suggestions
export function addMyFeedback(body) {
  return Post("doctors/addMyFeedback", body);
}

//Doctor Dashboard Question Pause
export function doctorDashboardQuestionPause(body) {
  return Post("question/doctorDashboardQuestionPause", body);
}