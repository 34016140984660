import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const WhatsappContactContain = styled.div`
    h4 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;
        color: #0077cc;
    }
    p {
        color: #757575;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        height: 40px;
        overflow: hidden;
    }
    .whatsapp-contact {
        background-color: inherit;
        left: 0;
        top: 0;
        margin: 0;
        background-image: none;
        background-position: -111px 0;
        &:hover {
            background-position: -111px -38px;
        }
    }
`;

export default WhatsappContactContain;
