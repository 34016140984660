import React, { useContext, useState, useRef } from "react";
import { Row, Col, Menu } from "antd";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "context/AuthProvider";
import {
  DOCTOR_DASHBOARD_PAGE,
  DOCTOR_PROFILE_PAGE,
} from "settings/constant";

const ProfileMenu = ({ avatar, history, userData }) => {
  const { logOut } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logOut();
    history.push("/");
  }

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        <Row gutter={8} className="text-justify">
          <Col lg={6}>{avatar}</Col>
          <Col lg={18}><h3> Dr. {userData?.first_name} </h3></Col>
        </Row>
      </div>
      <Menu className={`dropdown-menu ${state ? "active" : "hide"}`}>
        <Menu.Item onClick={closeDropdown} key="0">
          <NavLink to={DOCTOR_DASHBOARD_PAGE}> Dashboard </NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="1">
          <NavLink to={DOCTOR_PROFILE_PAGE}> Edit Profile </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <button onClick={handleLogout}> Log Out </button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(ProfileMenu);
