import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: europa;
    src: url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
        url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
        url(https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
            url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
            url(https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
            url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
            url(https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff2"),
            url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff"),
            url(https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("opentype");
        font-display: auto;
        font-style: italic;
        font-weight: 300;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff2"),
            url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("woff"),
            url(https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3) format("opentype");
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }
    @font-face {
        font-family: europa;
        src: url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff2"),
            url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff"),
            url(https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("opentype");
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }
    body, h1, h2, h3, h4, h5, h6, p {
        font-family: europa,sans-serif;
  }  
  .mymodal {
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     border: 1px solid #ccc;
     background: #fff;
     overflow: auto;
     border-radius: 12px;
     outline: none;
     padding: 20px;
     width: 100%;
     max-width: 700px;
    }
    .myoverlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 99999;
  }
  .modal-pop h5 {font-size: 22px;color: #0D1115;}
  .modal-pop h6 {font-size: 21px;color: #6E6E6E;margin-bottom: 0;}
  .modal-pop .form-label {font-size: 20px;display: block;width: 100%;color: #6E6E6E;margin-bottom: 5px;}
  .modal-set {margin-bottom: 20px;}
  .modal-set2 {margin-bottom: 10px;}
  .modal-set2 .ant-select-single.ant-select-show-arrow .ant-select-selection-item {line-height: 2.5;}
  .modal-set2 .ant-select-selector, .modal-set2 .ant-input {
    border: 1px solid #D8E0E8 !important;
    width: 100%;
  }
  .modal-set2 .ant-select-selector {
    height: 40px !important;
    border-radius: 30px !important;
  }
  .modal-set2 .ant-input {border-radius: 20px !important;}
  .modal-set2 .ant-select {width: 100%;}
  .modal-set2 .ant-checkbox-wrapper {color: #6E6E6E;font-size: 15px;}
  .modal-set2 .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #314755;
    border-color: #314755;
  }
  .modal-set2 .ant-checkbox:hover .ant-checkbox-inner, .modal-set2 .ant-checkbox-checked::after {border-color: #314755 !important;}
  .modal-set2 button {text-transform: uppercase;font-weight: bold;font-size: 14px;border-radius: 30px;}
  .modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 15px;
    cursor: pointer;
  }
  .review-modal .review-contain-right button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-radius: 30px;
    height: 38px;
    background-color: #0585FF;
    border-color: #0585FF;
    color: #fff;
    padding: 4px 25px;
    border: 0;
  }
  .review-modal .review-contain-right button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  .review-modal .review-contain-right button:focus {
    outline: 0;
  }
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet("primary.0", "#007bff")};
    color: ${themeGet("color.1", "#ffffff")};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: europa,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: europa,sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet("color.1", "#ffffff")};

    main.ant-layout-content {
      flex: 1 0 auto;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet("text.0", "#2C2C2C")};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet("text.2", "#777777")};
        justify-content: flex-start;
        strong {
          color: ${themeGet("text.0", "#2C2C2C")};
          width: 30%;
        }
      }
    }
  }

  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet("text.0", "#2C2C2C")};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet("text.0", "#2C2C2C")};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet("primary.0", "#007bff")};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet("primary.0", "#007bff")};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet("primary.0", "#007bff")};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet("text.0", "#2C2C2C")};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet("text.0", "#2C2C2C")};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet("text.0", "#2C2C2C")};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet("color.2", "#F7F7F7")};
          }
        }
      }
    }
  }

  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 99999 !important;
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet("color.1", "#ffffff")} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media only screen and (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media only screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media only screen and (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet("color.1", "#ffffff")};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet("primary.0", "#007bff")};
      svg {
        fill: ${themeGet("primary.0", "#007bff")};
      }
      &:hover {
        background-color: ${themeGet("primary.0", "#007bff")};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet("text.0", "#2C2C2C")};
    }
  }

  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        margin: 0 0 30px;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet("color.2", "#F7F7F7")};
        li {
          height: auto;
          line-height: 1;
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 700;
            color: ${themeGet("text.0", "#2C2C2C")};
          }
          &:last-child {
            a {
              color: ${themeGet("color.1", "#ffffff")};
              background-color: ${themeGet("primary.0", "#007bff")};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .main-menu {
        border: 0;
        padding-top: 0;
        li {
          padding: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            font-size: 15px;
            padding: 0 31px;
            border-left: 4px solid transparent;
            color: ${themeGet("text.0", "#2C2C2C")};
            &.active {
              font-weight: 700;
              border-color: ${themeGet("primary.0", "#007bff")};
              color: ${themeGet("primary.0", "#007bff")};
            }
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 31px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet("text.0", "#2C2C2C")};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            @media (max-width: 991px){
                border-left: 4px solid transparent;;
            }
            &:hover {
              color: ${themeGet("primary.0", "#007bff")};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet("primary.0", "#007bff")};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet("text.1", "#909090")};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet("text.0", "#2C2C2C")};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet("color.0", "#000000")};
        }
      } 
      &:hover {
        background-color: ${themeGet("color.2", "#F7F7F7")};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 50px;
            padding-bottom: 20px;
            border-color: ${themeGet("border.0", "#EBEBEB")};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet("border.0", "#EBEBEB")};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet("text.1", "#909090")};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet("text.0", "#2C2C2C")};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet("color.1", "#ffffff")};
    header {
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet("border.0", "#EBEBEB")};
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet("text.0", "#2C2C2C")};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet("primary.0", "#007bff")};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color: #007bffd1;
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet("border.0", "#EBEBEB")};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 3px;
    border-color: ${themeGet("border.3", "#E6E6E6")};
  }

  .ant-input-search-icon::before {
    border-color: ${themeGet("border.3", "#E6E6E6")};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${themeGet("primary.0", "#007bff")} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 132, 137, 0.2);
  }
  .no-data {
        text-align: center;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
        display: table;
        padding: 60px 30px;
        margin: 50px auto;
        border-radius: 20px;
        width: 100%;
        max-width: 500px;
        p {
            font-size: 15px !important;
            line-height: 1.4 !important;
            color: #989898 !important;
        }
    }
    .btn-link{
    border: none;
    background: none;
    box-shadow: none;
  }  
  .f-13 {
    @media (max-width: 575px){
        font-size: 13px !important;
    }
  }
  .foo-logo {
    @media (max-width: 991px){
        margin-left: -7px;
    }
  }
  header {
    .sticky-inner-wrapper {
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
        border-bottom: 1px solid #e6e6e6;
        > nav.transparent {
            position: relative;            
            max-width: 1120px;
            margin: 0 auto;
            box-shadow: none !important;
            > div {
                width: 100%;
                > div {
                    width: 50%;
                    &:last-child{
                        float: right;
                        > .auth_menu {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
 }
 .claim-popup {
    .ant-modal-content {
        border-radius: 20px;
        .ant-modal-body {
            padding: 3rem;
            h4 {
                color: rgb(59, 62, 64);
                font-size: 32px;
                text-align: center;
                font-weight: bold;
                margin-bottom: 0;
                line-height: normal;
            }
            p {
                padding: 10px 15px;
                text-align: center;
                font-size: 14px;
                margin-bottom: 1.25rem;
                .span-anchor {
                    color: rgb(5, 133, 255);
                    font-weight: 700;
                    cursor: pointer;
                }
            }
            .form-control {
                margin-bottom: 11px;
                .field-label {
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 400;
                    color: rgb(44, 44, 44);
                    padding: 0px;
                    display: block;
                    margin-bottom: 10px;
                    text-transform: inherit;
                }
                .ant-input {
                    min-height: 36px;
                    border-color: rgb(230, 230, 230);
                }                
            }
            .ant-btn-primary {
                padding-left: 30px;
                padding-right: 30px;
                border-radius: 20px;
                text-align: center;
                margin: 25px auto 0;
                max-width: 200px;
                display: table;
                height: 32px;
                padding: 4px 16px;
                font-size: 14px;
            }
        }
    }
    &.claim-popup-status {
        .ant-modal-content {
            border-radius: 20px;
            min-height: 400px;
            display: flex;
            align-items: center;
            .ant-modal-body {
                padding: 3rem;
                .anticon-check-circle {
                    color: #339259;
                    font-size: 57px;
                    margin: 0 auto 15px;
                    display: table;
                }
                h4 {
                    font-size: 23px;
                    line-height: 1.2;
                }
            }
        }
    }
}
.ant-modal {
    .ant-modal-content {
        border-radius: 15px;
    }
    .ant-modal-body {
        &::after {
            content: '';
            position: absolute;
            top: 20px;
            left: -8px;
            width: 15px;
            height: 30px;
            background-color: #0077cc;
        }
    }
}
.Qa-reply {
    h4 {
        font-weight: 600;
        font-size: 16px;
    }
    h5 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;
        color: #0077cc;
    }
    textarea.form-control {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 10px;
        color: #757575;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        &:focus {
            outline: none;
        }
    }
    .ant-table-thead {
        > tr {
            > th {
                font-weight: bold;
                background: #eef2fb;
                padding: 12px 16px;
            }
        }
    }
    .ant-table-tbody {
        td {
            padding: 12px 16px;
            .ant-space-item {
                margin-right: 0 !important;
                a {
                    cursor: pointer;
                    border: 1px solid #324755;
                    color: #324755;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 5px 12px;
                    border-radius: 20px;
                    margin: 0 2px;
                    font-size: 10px;
                    display: inline-block;
                    &:hover {
                        background-color: #324755;
                        color: #fff;
                    }
                }
            }
        }
    }
    .ant-table-pagination {
        button {
            margin-top: 0;
        }
    }
    .ant-table-cell {
        input.form-control {
            min-height: 36px;
            border: 1px solid #E6E6E6;
            border-radius: 3px;
            padding: 4px 11px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            width: 100%;
            max-width: 270px;
            &:focus {
                outline: none;
                border-color: #324755;
            }
        }
        .anticon-plus-circle {
            color: #0077cc;
            font-size: 20px;
            cursor: pointer;
        }
    }
    button {
        margin-top: 10px;
    }
    &.cancel-booking {
        .cancel-details {
            background-color: #F8F8F8;
            padding: 15px;
            border-radius: 15px;
            p {
                margin-bottom: 15px;
                label {
                    margin: 0;
                    display: block;
                    font-weight: 500;
                    line-height: 1;
                    color: #959595;
                }
                span {
                    display: block;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
        .cancel-reason {
            padding: 20px 0;
            label {
                display: block;
                width: 100%;
            }
        }
    }
}
.pt-40 {
    padding-top: 40px !important;
}
.photo-count {
    padding: 10px;
    background-color: #fafafa;
    border-radius: 10px;
    display: block;
    margin-top: 15px;
    text-align: center;
}
.profile-left {
    aside {
        border: 0 none;
        box-shadow: 0px 0px 10px -2px #ddd;
        .ant-menu-inline {
            border: 0 none;
        }
        > div {
            padding: 20px;
            .avatarPic {
                padding-bottom: 0 !important;
                display: inline-flex;
                > div {
                    margin: auto 0;
                    text-align: left;
                    padding-left: 12px;
                    h3 {
                        margin: 0;
                    }
                }
                .ant-upload-picture-card-wrapper {
                    max-width: 80px;
                    float: left;
                    &::before {
                        position: absolute;
                    }
                    .ant-upload.ant-upload-select-picture-card {
                        margin: 0;
                        width: 80px;
                        height: 80px;
                        img {
                            width: 80px;
                            height: 80px;
                        }
                    }
                }                
            }
            &:last-child {
                padding: 0;
            }
        }
    }
    .ant-menu-item {
        margin-top: 0;
    }
    .ant-menu:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
            background-color: #0077cc;
            color: #fff;
            a {
                color: #fff;
            }
            &::after {
                border-right: 3px solid #0077cc;
            }
        }
    }
}
.success-work {
    h4 {
        margin-bottom: 30px;
    }
    .Or-div {
        span {
            text-transform: uppercase;
        }
    }
    .success-row {
        justify-content: space-between;
        align-items: center;
        max-width: 270px;
        .ant-upload.ant-upload-select-picture-card {
            margin: 0;
        }
    }
    .form-control {
        margin-bottom: 5px;
        display: inline-block;
        width: 100%;
        > label {
            font-size: 14px;
            line-height: 1;
            font-weight: 400;
            color: rgb(44, 44, 44);
            padding: 0px;
            margin-bottom: 10px;
            text-transform: inherit;
            display: inline-block;
            width: 200px;
            float: left;
        }
        .ant-picker {
            max-width: 270px;
        }
        > input {
            display: inline-block;
            min-height: 36px;
            float: left;
            max-width: 270px;
        }
        > .drop-right {
            display: inline-block;
            min-height: 36px;
            float: left;
            max-width: 270px;
            width: 100%;
            .ant-select-selector {
                min-height: 36px !important;
                input {
                    min-height: 36px !important;
                }
            }
        }
        > .ant-select {
            display: inline-block;
            min-height: 36px;
            float: left;
            max-width: 270px;
            .ant-select-selector {
                min-height: 36px !important;
                input {
                    min-height: 36px !important;
                }
            }
        }
        > textarea {
            display: inline-block;
            float: left;
            max-width: 270px;
        }        
        > .ant-upload-picture-card-wrapper {
            width: auto;
        }
        .ant-checkbox-wrapper .ant-checkbox + span {
            font-size: 14px;
            font-weight: 400;
            color: rgb(44,44,44);
            line-height: 1;
        }
        .ant-rate {
            font-size: 14px;
            margin-bottom: 6px;
            li {
                margin-right: 3px;
            }
        }
        .Qa-dash-set {
            > .ant-row {
                max-width: 270px;
            }
            .ant-upload.ant-upload-select-picture-card {
                width: 100%;
                max-width: 125px;
            }
        }
    }
}
.doct-box-style {
    .box-set-item {
        > div:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    .ant-card {
        .ant-card-body {
            border: 0 none !important;
            padding: 0 !important;            
            // box-shadow: 0px 0px 10px -2px #ddd;
            // overflow: inherit !important;
            // position: relative;
            // &::after {
            //     content: '';
            //     position: absolute;
            //     top: 30px;
            //     left: -8px;
            //     width: 15px;
            //     height: 40px;
            //     background-color: #0077cc;
            // }
        }
    }
}
.box-set {
    box-shadow: 0px 0px 10px -2px #ddd;
    border-radius: 15px;
    margin-bottom: 30px;
    .box-set-title {
        padding: 15px;
        h5 {
            position: relative;
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            display: inline-block;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -24px;
                width: 15px;
                height: 31px;
                background-color: #0077cc;
            }
        }
        .applyAll {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            padding: 0 15px;
            border-radius: 30px;
            float: right;
            margin-bottom: 20px;
        }
        .addCircle {
            margin-bottom: 0;
        }
    }
    .box-set-body {
        padding: 15px;
        border-top: 1px solid #f7f4f4;
        .justify-space-between {
            justify-content: space-between;
            display: flex;
        }
        .form-sect {
            padding: 25px 10%;
            border: 0 none;
            margin-bottom: 0;
            border-radius: 0;
            &:not(:last-child){
                border-bottom: 1px solid #E6E6E6;
            }
            .closeIcon {
                > span {
                    top: -18px;
                    right: 0px;
                }
            }
        }
        #about::-webkit-scrollbar {
          width: 5px;
        } 
        #about::-webkit-scrollbar-track {
          background-color: #fcfcfc;
          border-radius: 50%;
        } 
        #about::-webkit-scrollbar-thumb {
          background-color: #1890ff;
        }
        .article-table{
            thead {
                > tr {
                    > th {
                        font-weight: bold;
                        background: #eef2fb;
                        padding: 12px 16px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 12px 16px;
                    }
                }
            }
        }        
    }
    .ant-upload.ant-upload-drag {
        bottom: 16px;
    }
    .drag-drop {
        margin-bottom: 0;
        .cover-img {
            height: 200px;
        }
    }    
}
.feedback {
    span {
        display: inline-block;
        float: right;
    }
}
.dash-right {
    .box-set-match {
        position: relative;
        text-align: left;
        &::after {
            content: '';
            position: absolute;
            top: 14px;
            left: -9px;
            width: 15px;
            height: 31px;
            background-color: #0077cc;
        }
        .box-set {
            .box-set-title {
                h5 {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
.dash-sect {
    .box-set {
        height: 412px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        } 
        &::-webkit-scrollbar-track {
          background-color: #fcfcfc;
        } 
        &::-webkit-scrollbar-thumb {
          background-color: #1890ff;
        }
    }
}
.book-status {
    text-align: center;
    .ant-modal-content {
        min-height: 350px;
        display: flex;
        align-items: center;
    }
    .ant-modal-body {
        width: 100%;
        padding: 55px 24px;
        h3 {
            font-weight: bold;
            font-size: 28px;
        }
        h4 {
            font-weight: bold;
            font-size: 25px;
            line-height: normal;
        }
        .anticon-check-circle {
            color: #178a2b;
            font-size: 48px;
            margin-bottom: 12px;
        }
        .anticon-close-circle {
            color: #c7592f;
            font-size: 48px;
            margin-bottom: 12px;
        }
        p {
            font-size: 16px;
            line-height: normal;
        }
        > span {
            display: block;
            font-style: italic;
            margin-bottom: 10px;
        }
        a.applyAll {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
            padding: 0 15px;
            border-radius: 30px;
            margin-bottom: 0;
            color: #fff;
            background: #1890ff;
            border-color: #1890ff;
            text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
            -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
            box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
            height: 32px;
            line-height: 32px;
            display: inline-block;
            &:hover {
                background: #40a9ff;
                border-color: #40a9ff;
            }
        }
    }
}
header {
    .sticky-inner-wrapper {
        background-color: #fff;
        > .is_default {
            width: 100%;
            margin: 0 auto;
            justify-content: space-between;
        }
        > .is_transparent {
            position: relative;
            max-width: 1120px;
            margin: 0px auto;
            box-shadow: none !important;
            > div {
                width: 100%;
                > div {
                    width: 50%;
                    &:last-child {
                        float: right;
                        > .auth_menu {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}
.lh-1 {
    line-height: 1;
}
.lh-normal {
    line-height: normal;
}
.whatsapp-modal {
    .ant-modal-body {
        > div {
            margin: 25px 0;
            .anticon-whats-app {
                color: #0077cc;
                font-size: 30px;
                margin-bottom: 7px;
            }
            p {
                color: #0077cc;
                margin-bottom: 0;
                font-weight: 600;
            }
        }
    }    
}
.emirates-filter {
    width: 115px !important;
    .ant-select-item-option-content {
        font-size: 13px;
    }
}
.no-data-contain {
    text-align: center;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    display: table;
    padding: 60px 30px;
    margin: 50px auto;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    h5 {
        font-size: 18px;
        font-weight: bold;
        color: #0077cc;
        margin-bottom: 0;
    }
    p.error-content {
        color: #757575;
        font-size: 17px;
        max-width: 300px;
        margin: 0px auto;
        text-align: center;
    }
}
.no-data {
    text-align: center;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    display: table;
    padding: 60px 30px;
    margin: 50px auto;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    h5 {
        font-size: 18px;
        font-weight: bold;
        color: #0077cc;
        margin-bottom: 0;
    }
    p.error-content {
        color: #757575;
        font-size: 17px;
        max-width: 300px;
        margin: 0px auto;
        text-align: center;
    }
}
.no-photo {
    text-align: center;
    display: table;
    padding: 30px 30px;
    margin: 50px auto;
    border-radius: 20px;
    width: 100%;
    background-color: #fff;
    h5 {
        font-size: 18px !important;
        font-weight: bold !important;
        color: #0077cc !important;
        margin-bottom: 0 !important;
    }
    p.error-content {
        color: #757575 !important;
        font-size: 14px !important;
        margin: 10px auto !important;
        text-align: center !important;
        white-space: inherit !important;
    }
    .no-review-img{
        width: auto !important;
        height: auto !important;
        margin-right: 0 !important;
        border-radius: 0% !important;
        object-fit: fill !important;
        max-width: 80px !important;
        margin-bottom: 10px !important;
        border: 0 none !important;
    }
}
.ant-modal-wrap {
    background: rgba(0,0,0,0.15);
    .edit-reply {
        .ant-modal-content {
            box-shadow: none;
            .ant-modal-header {
                border-radius: 15px 15px 0 0;
            }
        }
    }
}
.feedback-modal {
    .ant-modal-header {
        border-radius: 15px 15px 0 0;
    }
}
.lightbox-modal {
    width: 100% !important;
    height: 100% !important;
    max-height: 500px;
    top: 0px;
    max-width: 1364px;
    .ant-modal-content {
        border-radius: 0;
        background-color: inherit;
        .ant-modal-close-x {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
        .ant-modal-body {
            .slick-slider {
                .slick-prev {
                    left: 20px;
                    z-index: 9;
                    &:before {
                        font-size: 35px;
                    }
                }
                .slick-next {
                    right: 20px;
                    z-index: 9;
                    &:before {
                        font-size: 35px;
                    }
                }
            }
            .slick-slide {
                .slot-item {
                    text-align: center;
                    h4 {
                        color: #fff;
                        font-size: 20px;
                        margin-bottom: 15px;
                        line-height: normal;
                    }
                    .light-img-contain {
                        min-height: 480px;
                        align-items: center;
                        display: flex;
                        max-width: 1000px;
                        margin: 0 auto;
                        img {
                            margin: 0 auto;
                            max-height: 470px;
                        }
                    }
                    p {
                        color: #fff;
                        line-height: normal;
                        margin: 15px 0 0;
                    }
                }
            }
            &::after {
                display: none;
            }
        }
    }
}
.prevImage {
    .ant-modal-content {
        padding: 25px;
    }
}

.mt-8 {margin-top: 8px;}
.my-20 {margin-top: 20px;margin-bottom: 20px;}
.pl-10 {padding-left: 10px;}
.pr-10 {padding-right: 10px;}
.pt-10 {padding-top: 10px;}
.pb-10 {padding-bottom: 10px;}
.mt-10 {margin-top: 10px;}
.mr-10 {margin-right: 10px;}
.mb-0 {margin-bottom: 0;}
.mb-5 {margin-bottom: 5px;}
.mt-20 {margin-top: 20px;}
.mb-20 {margin-bottom: 20px;}
.pb-20 {padding-bottom: 20px;}
.pr-30 {padding-right: 30px;}
.w-100 {width: 100%;}
.w-80 {width: 80%;}
.text-center {text-align: center;}
.text-danger {color: red;}
.text-blue {#0077cc;}
.text-right {text-align: right;}
.text-left {text-align: left;}
.text-justify {text-align: justify;}
.align-items-left {align-items: left;}
.align-items-flex-start {align-items: flex-start;}
.float-right {float: right;}
.red-style {font-size: 16px;color: red;margin-bottom: 20px;}
.cursor-pointer {cursor: pointer;}
.space-between {justify-content: space-between;}
.font-12 {font-size: 12px;}
.flex-css {display:flex;align-items: center;}
.ant-modal-header {border-radius: 15px 15px 0 0;}
`;

export default GlobalStyle;
