import React from "react";
import { Menu } from "antd";
import {
    TC_PAGE,
} from "settings/constant";

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <a target="_blank" href={process.env.REACT_APP_SITE_URL + "about-us"}>
          About Us
        </a>
      </Menu.Item>
      <Menu.Item key="1">
          <a
              target="_blank"
              href={process.env.REACT_APP_SITE_URL + "privacy-policy"}
          >
              Privacy
          </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          target="_blank"
          href={TC_PAGE}
          // href={process.env.REACT_APP_SITE_URL + "terms-conditions"}
        >
          Terms & Conditions
        </a>
      </Menu.Item>
      <Menu.Item key="3">
      <a
          target="_blank"
          href={process.env.REACT_APP_SITE_URL + "payment-policy"}
        >
          Payment Policy
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;
