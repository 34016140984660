import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import FormControl from "components/UI/FormControl/FormControl";
import FeedbackContain from './Feedback.style';
import { Modal, Button, Input, notification } from 'antd';
import { addMyFeedback } from '../../services/AuthService';
import { AuthContext } from "../../context/AuthProvider";
import feedbackIcon from 'assets/images/feedback-ic.png';

const FeedbackModal = ({ show }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [submitLoding, setSubmitLoading] = useState(false);
    let userData = localStorage.getItem("userData");
    const userD = JSON.parse(userData);
    const { control, errors, handleSubmit, reset } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        if (show) {
            showModal();
        }
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSubmit = (data) => {
        setSubmitLoading(true);

        if (userD && userD?.id) {
            let obj = {
                id_doctor: userD?.id,
                feedback: data.feedback
            }

            addMyFeedback(obj).then((res) => {
                if (res.data.status == "Success") {
                    notification["success"]({
                        message: res.data.status,
                        description: res.data.message,
                        placement: "bottomRight",
                    });
                } else {
                    notification["error"]({
                        message: res.data.status,
                        description: res.data.message,
                        placement: "bottomRight",
                    });
                }
                setTimeout(() => {
                    handleCancel()
                }, 1);
                setSubmitLoading(false);
                reset({});
            }).catch((error) => {
                console.log(error);
            })
        }
    };

    return (
        <>
            <Button type="primary" className="feedback-btn" shape="round" onClick={showModal}>
                <img src={feedbackIcon} alt="Feedback Icon" width="57" height="44" /> Feedback
            </Button>
            <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Let us know how we're doing"
                className="feedback-modal"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label="Feedback/Suggestion"
                        htmlFor="feedback"
                        error={
                            errors.feedback && <span>This field is required!</span>
                        }
                    >
                        <Controller
                            as={<Input.TextArea rows={5} />}
                            type="text"
                            id="feedback"
                            name="feedback"
                            placeholder="Please give us your feedback. Your opinion is very important to us. We appreciate your feedback and will use it to evaluate changes and make improvements in our portal."
                            control={control}
                            rules={{ required: true }}
                        />
                    </FormControl>

                    <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        disabled={submitLoding}
                    >
                        {submitLoding ? "Submitting..." : "Submit"}
                    </Button>
                </form>
            </Modal>
        </>
    );
};
export default function Feedback() {
    const { showFeedBack } = useContext(AuthContext);
    return (
        <FeedbackContain>
            <FeedbackModal show={showFeedBack} />
        </FeedbackContain>
    );
}